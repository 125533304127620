import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const NotFoundPage: React.FC = () => {
    useEffect(() => {
        navigate('/') // redirecting to home page
    }, [])
    return null
}

export default NotFoundPage
